<template>
  <div class="left-box">
    <div class="logo">
      <img
        src="static/images/logo.png"
        style="width: 65px; height: 65px; margin-top: 20px; margin-bottom: 20px"
      />
    </div>
    <el-link
      type="primary"
      :underline="false"
      style="color: #e64242"
      target="_blank"
      rel="noopener noreferrer"
      href="https://shimo.im/docs/gXqmeXdB2NTJa4qo/"
    >
      万玻商机王
    </el-link>
    <!-- <el-link
      type="primary"
      :underline="false"
      style="margin-top: 20px; color: #e64242"
      v-if="this.loginInfo.version == 1"
      target="_blank"
      rel="noopener noreferrer"
      href="https://shimo.im/docs/gXqmeXdB2NTJa4qo/"
    >
      欢迎您！入门版会员
    </el-link>
    <el-link
      type="primary"
      :underline="false"
      style="margin-top: 20px; color: #e64242"
      v-if="this.loginInfo.version == 2"
      target="_blank"
      rel="noopener noreferrer"
      href="https://shimo.im/docs/gXqmeXdB2NTJa4qo/"
    >
      欢迎您！基础版会员
    </el-link>
    <el-link
      type="primary"
      :underline="false"
      style="margin-top: 20px; color: #e64242"
      v-if="this.loginInfo.version == 3"
      target="_blank"
      rel="noopener noreferrer"
      href="https://shimo.im/docs/gXqmeXdB2NTJa4qo/"
    >
      欢迎您！旗舰版会员
    </el-link>
    <el-link type="primary" :underline="false" style="color: #e64242">
      到期时间：{{ this.loginInfo.due_date }}
    </el-link> -->
   
    <el-link type="primary" :underline="false"></el-link>
    <el-link type="primary" :underline="false"></el-link>
    <el-menu
      class="sidebar"
      :unique-opened="true"
      :default-active="currentPagePath"
      @open="handleOpen"
      :router="true"
      @close="handleClose"
    >
      <el-menu-item index="/dashboard/welcome">
        <i class="el-icon-s-home"></i>
        <span>主页</span>
      </el-menu-item>
      <!-- <el-menu-item index="/dashboard/analysis">
        <i class="el-icon-monitor"></i>
        <span>统计分析</span>
      </el-menu-item> -->

      <el-submenu index="xuanchuan">
        <template slot="title">
          <i class="el-icon-star-on"></i>
          <span>展示宣传</span>
        </template>
        <el-menu-item index="/dashboard/company">
          <i class="el-icon-s-shop"></i>
          <span>公司资料</span>
        </el-menu-item>
        <el-menu-item index="/dashboard/ad">
          <i class="el-icon-picture-outline"></i>
          <span>宣传图</span>
        </el-menu-item>
        <el-menu-item index="/dashboard/news">
          <i class="el-icon-news"></i>
          <span>公司新闻</span>
        </el-menu-item>

        <el-menu-item index="/dashboard/recuirt">
          <i class="el-icon-user-solid"></i>
          <span>人才招聘</span>
        </el-menu-item>
      </el-submenu>

      <el-submenu index="user">
        <template slot="title">
          <i class="el-icon-s-goods"></i>
          <span>产品供应</span>
        </template>
        <el-menu-item index="/dashboard/goods">
          <i class="el-icon-s-goods"></i>
          <span>产品列表</span>
        </el-menu-item>
        <el-menu-item index="/dashboard/category">
          <i class="el-icon-s-help"></i>
          <span>分类管理</span>
        </el-menu-item>
      </el-submenu>

      <el-submenu index="order">
        <template slot="title">
          <i class="el-icon-s-promotion"></i>
          <span>商机订单</span>
        </template>
        <el-menu-item index="/dashboard/order">
          <i class="el-icon-s-promotion"></i>
          <span>订单管理</span>
        </el-menu-item>
        <el-menu-item index="/dashboard/order/oppo">
          <i class="el-icon-s-ticket"></i>
          <span>商机发现</span>
        </el-menu-item>
      </el-submenu>

      

      <el-submenu index="settings">
        <template slot="title">
          <i class="el-icon-warning"></i>
          <span>设置</span>
        </template>
        <el-menu-item index="/dashboard/admin">
          <i class="el-icon-user-solid"></i>
          <span>管理员</span>
        </el-menu-item>
      </el-submenu>
      <el-menu-item @click="logout">
        <i class="el-icon-switch-button"></i>
        <span>退出</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
import api from '@/config/api';

export default {
  data() {
    return {
      currentPagePath: "/dashboard",
      loginInfo: null,
      root:''
    };
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    logout() {
      this.$confirm("是否要退出?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        localStorage.clear();
        this.$router.replace({ name: "login" });
      });
    },
  },
  mounted() {
    console.log(this.$route.path);
this.root = api.rootUrl;
    if (!this.loginInfo) {
      this.loginInfo = JSON.parse(
        window.localStorage.getItem("userInfo") || null
      );
    }
    if (this.loginInfo) {
      let loginStatus = false;
      this.axios
        .post(this.root + "auth/login", {
          username: this.loginInfo.account,
          password: this.loginInfo.pwd,
        })
        .then((res) => {
          let call = res.data;
          if (res.data.errno === 0) {
            localStorage.setItem("token", res.data.data.token);
            localStorage.setItem(
              "userInfo",
              JSON.stringify(res.data.data.userInfo)
            );
             if(!res.data.data.userInfo.main_business){
              this.$router.push({ name: 'company' });
              }else{
              this.$router.push({ name: 'welcome' });

              }
          } else {
            this.$message({
              type: "error",
              message: call.errmsg,
            });
            localStorage.clear();
            this.$router.replace({ name: "login" });
            return false;
          }
        });
    }
  },
};
</script>
<style>
.left-box {
  width: 200px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  height: 100%;
  float: left;
  flex-direction: column;
  background: #fff;
}

.left-box .sidebar {
  width: 200px;
  flex: 1;
  border-radius: 0;
  /*background: #233445;*/
}

.left-box .fa {
  margin-right: 10px;
  font-size: 18px;
  /*color: #ccc;*/
}

.left-box span {
  /*color: #ccc;*/
}

.left-box .el-submenu .el-menu-item .fa {
  margin-right: 10px;
  font-size: 10px;
}

/*渐变背景*/
.back {
  background: -webkit-linear-gradient(138deg, #8731e8 0%, #4528dc 100%);
  background: -o-linear-gradient(138deg, #8731e8 0%, #4528dc 100%);
  background: linear-gradient(-48deg, #8731e8 0%, #4528dc 100%);
  opacity: 0.7;
}

.left-box .logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 72x;
  width: 200px;
  border-right: solid 1px #e6e6e6;
  /*box-shadow: 0 1px 1px rgba(0, 0, 0, .5);*/
}

.left-box .logo img {
  height: 60px;
}
</style>
